import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import Layout from '../components/layout'
import { Header } from '../components/header'

import { Container, Row, Col } from 'react-bootstrap'

import avatar from '../images/general/avatar.svg'

const ErrorPage=() => {

    return(
        <>
        <Helmet>
            <title>GoRead: Page Not Found</title>
        </Helmet>
        <Layout>
            <Header />
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} xl={9}>
                        <div className="errorHolder">
                            <img className="errorHolder_img" src={avatar} alt='' />
                            <h1 className="heading">Oops!</h1>
                            <p className="mb-2">Sorry we couldn't find that page.</p>
                            <Link className="primary-btn d-inline-block" to="/">Get me out of here!</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
        </>
    )
}

export default ErrorPage